enum SocketEvents {

  // GENERAL
  CONNECTED,

  // Classic
  HISTORICAL_DATA_CLASSIC_LOADED,
  HISTORICAL_DATA_CLASSIC_ALL_LOADED,

  // Future
  HISTORICAL_DATA_FUTURE_LOADED,
  HISTOIRCAL_DATA_FUTURE_ALL_LOADED,
  FUTURE_MINI_TICKER,
}

export default SocketEvents;