import SocketEvents from "../../enums/Socket/SocketEvents.js";
import SocketClient from "./app.socket.js";

type ChartType = "candlestick" | "line";
type ChartEnginge = "apexcharts" | "chartjs";

$(function () {
  const socketService = SocketClient.getInstance();
  const loadedCoins: {
    classic: Record<string, any>;
    future: Record<string, any>;
  } = {
    classic: {},
    future: {},
  };

  const existingCharts: string[] = [];

  // Options
  const $showNumberOfCandles = $("#show-n-candlesticks");
  const $sortByChangeInSticks = $("#sort-by-change-in-sticks");

  const chartType: ChartType = "line";
  const chartEngine: ChartEnginge = "apexcharts";
  const candleStickLimit = -120;
  const changeOfLastNCandles = 5;
  const $charts: any = {};
  const $chartTemplate = $(".chart-template").clone();
  const $chartContainer = $("#charts-container");
  const chartData = {};
  const fullChartData = {};
  let chartChange = [];

  $(".chart-template").remove();

  socketService.subscribeToNotifications((event, data) => {
    switch (event) {
      case SocketEvents.CONNECTED:
        // console.log("Connected to server", data.allLoadedCoins);
        mergeLoadedCoins("classic", data.loadedCoins);
        mergeLoadedCoins("future", data.loadedFutureCoins);
        break;
      case SocketEvents.HISTORICAL_DATA_CLASSIC_LOADED:
        // console.log("Historical data loaded for classic");
        break;
      case SocketEvents.HISTORICAL_DATA_CLASSIC_ALL_LOADED:
        // console.log("All historical data loaded for classic");
        break;
      case SocketEvents.HISTORICAL_DATA_FUTURE_LOADED:
        // console.log("Historical data loaded for future", data);
        mergeLoadedCoins("future", { [data.coinPair]: data.data });
        break;
      case SocketEvents.HISTOIRCAL_DATA_FUTURE_ALL_LOADED:
        // console.log("All historical data loaded for future");
        break;
      case SocketEvents.FUTURE_MINI_TICKER:
        handleFutureMiniTickers(chartType, data.data);
        // handleFutureMiniTicker(chartType, data);
        break;
    }
  });

  const mergeLoadedCoins = (type: string, coins: Record<string, any>) => {
    Object.keys(coins).forEach((coin) => {
      if (!loadedCoins[type][coin]) {
        loadedCoins[type][coin] = coins[coin].slice(-30);
      }
    });

    validateCharts();
  };

  const validateCharts = () => {
    const keys = Object.keys(loadedCoins.future);
    for (const key of keys) {
      const chartsCount = $chartContainer.children().length;

      if (existingCharts.includes(key)) {
        continue;
      }

      existingCharts.push(key);

      const { isNew, $template } = appendNewContainer(key);

      if ($template === null) {
        continue;
      }

      loadChartData(chartEngine, chartType, key, true);
    }
  };

  const appendNewContainer = (key: string) => {
    // Validate if element with the same ID exists
    const existingElement = $(`#future-${key}`);
    if (existingElement.length > 0) {
      return { isNew: false, $template: existingElement };
    }

    const displayingNCharts = Number($("#display-n-charts").val());
    const existingCharts = $chartContainer.children().length;

    if (existingCharts >= displayingNCharts) {
      return { isNew: false, $template: null };
    }

    const $template = $chartTemplate.clone();
    $template.attr("id", `future-${key}`);
    $template.find(".chart-template-chart").attr("id", `future-${key}-chart`);
    $template.find("canvas.chart-template-chart").attr("id", `future-${key}-canvas`);
    $template.find(".chart-template-title").text(key);
    $template.find(".card-header a").attr("href", `https://www.binance.com/en/futures/${key}`);
    $chartContainer.append($template);

    return { isNew: true, $template };
  };

  const loadChartData = (chartEngine: ChartEnginge, chartType: ChartType, coinPair: string, firstLoad: boolean = false) => {
    const data = loadedCoins.future[coinPair];

    if (data.length === 0) {
      return;
    }

    if (chartEngine === "chartjs") {
      return createChartJSChart(chartType, coinPair, data);
    }

    const name = `future-${coinPair}`;

    const options = {
      series: [
        {
          data: [],
        },
      ],
      chart: {
        type: chartType,
        toolbar: {
          show: false,
        },
        height: 350,
        animations: {
          enabled: false, // Disable animations
        },
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        tooltip: {
          enabled: false,
        },
      },
      plotOptions: {
        candlestick: {
          colors: {
            upward: "#3C90EB",
            downward: "#DF7D46",
          },
        },
      },
    };

    const nCandles = Number($showNumberOfCandles.val());

    if (chartType === "candlestick") {
      const finalFullData = data.slice(candleStickLimit).map((item) => ({ x: new Date(item.time), y: [item.open, item.high, item.low, item.close] }));
      const finalData = finalFullData.slice(nCandles * -1);

      options.series[0].data = finalData;
      chartData[name] = finalData;
      fullChartData[name] = finalFullData;
    } else if (chartType === "line") {
      const finalFullData = data.slice(candleStickLimit).map((item) => ({ x: new Date(item.time), y: item.close }));
      const finalData = finalFullData.slice(nCandles * -1);

      options.series[0].data = finalData;
      chartData[name] = finalData;
      fullChartData[name] = finalFullData;
    }

    console.log("Creating chart", name, options);

    // @ts-ignore
    $charts[name] = new ApexCharts(document.querySelector(`#${name}-chart`), options);
    $charts[name].render();
  };

  const createChartJSChart = (chartType: ChartType, coinPair: string, data: Record<string, any>) => {
    const name = `future-${coinPair}`;
    // @ts-ignore
    const ctx = document.getElementById(`future-${coinPair}-canvas`).getContext("2d");
    const nCandles = Number($showNumberOfCandles.val());
    const finalFullData = data.slice(candleStickLimit).map((item) => ({ x: new Date(item.time), y: item.close }));
    const finalData = finalFullData.slice(nCandles * -1);

    // @ts-ignore
    $charts[name] = new Chart(ctx, {
      type: "line",
      data: {
        datasets: [
          {
            data: finalData,
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            fill: false, // Prevent area fill below the line
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            type: "linear",
            position: "bottom",
            ticks: {
              display: false, // Hide x-axis labels
            },
            title: {
              display: false,
            },
          },
          y: {
            title: {
              display: false,
            },
          },
        },
        animation: false,
      },
    });

    chartData[name] = finalData;
    fullChartData[name] = finalFullData;
  };

  const handleFutureMiniTickers = (chartType: ChartType, data: any[]) => {

    for (const item of data) {
      handleFutureMiniTicker(chartType, item);
    }

  };

  const handleFutureMiniTicker = (chartType: ChartType, data: Record<string, any>) => {
    // Calculate the upper minute
    const eventTimeToUpperMinute = Math.floor(data.time / 60000) * 60000;

    let newData: { x: Date; y: [number, number, number, number] | number } = { x: new Date(eventTimeToUpperMinute), y: [Number(data.open), Number(data.high), Number(data.low), Number(data.close)] };

    if (chartType === "line") {
      newData = { x: new Date(eventTimeToUpperMinute), y: Number(data.close) };
    }

    addOrUpdateCandlestick("future", data.symbol, newData);
  };

  const addOrUpdateCandlestick = (type: string, coinPair: string, newCandlestick: { x: Date; y: [number, number, number, number] | number }) => {
    if (!chartData[`${type}-${coinPair}`]) {
      return;
    }

    const nCandles = Number($showNumberOfCandles.val());
    const $chart = $charts[`${type}-${coinPair}`];
    const existingData = fullChartData[`${type}-${coinPair}`].slice(candleStickLimit);
    const index = existingData.findIndex((candle) => {
      return candle.x.getTime() === newCandlestick.x.getTime();
    });

    if (index !== -1) {
      // If the candlestick exists, update it
      existingData[index] = newCandlestick;
    } else {
      // If the candlestick doesn't exist, add it
      // Add to the top of the array
      existingData.push(newCandlestick);
    }

    const finalFullChartData = existingData.slice(candleStickLimit);
    const finalChartData = finalFullChartData.slice(nCandles * -1);

    fullChartData[`${type}-${coinPair}`] = finalFullChartData;
    chartData[`${type}-${coinPair}`] = finalChartData;

    // Update the series with the new or updated data
    $chart?.updateSeries([
      {
        data: finalChartData,
      },
    ]);
  };

  const updateChartsOrder = async (chartType: ChartType) => {
    chartChange = [];
    for (const coinPair in loadedCoins.future) {
      await updateChartOrderForRemoval(chartType, "future", coinPair);
    }

    // orderCharts();
    orderChartsRemoving();
  };

  const updateChartOrder = async (chartType: ChartType, type: string, coinPair: string): Promise<void> => {
    // Calculate the absolute value of the change
    const data = chartData[`${type}-${coinPair}`];
    const lastCandles = data.slice(Number($sortByChangeInSticks.val()) * -1);
    const firstCandle = lastCandles[0];
    const lastCandle = lastCandles[lastCandles.length - 1];

    if (!firstCandle || !lastCandle) {
      return;
    }

    const firstClose = chartType === "candlestick" ? firstCandle.y[3] : firstCandle.y;
    const lastClose = chartType === "candlestick" ? lastCandle.y[3] : lastCandle.y;
    const change = ((lastClose - firstClose) / firstClose) * 100;

    // Update the order of the chart

    const $chart = $(`#${type}-${coinPair}`).data("order", Math.abs(change));
    $chart.find(".chart-template-subtitle").text(` (${change.toFixed(2)}%)`);
  };

  const itemMapper = (item) => {
    // @ts-ignore
    if (chartType === "candlestick") {
      return {
        x: new Date(item.time),
        y: [item.open, item.high, item.low, item.close],
      };
    } else if (chartType === "line") {
      return {
        x: new Date(item.time),
        y: item.close,
      };
    }
  };

  const updateChartOrderForRemoval = async (chartType: ChartType, type: string, coinPair: string): Promise<void> => {
    // Calculate the absolute value of the change
    let data = fullChartData[`${type}-${coinPair}`];
    const futureData = loadedCoins.future[coinPair];

    if (data === undefined && futureData === undefined) {
      return;
    }

    if (data === undefined) {
      data = futureData.map(itemMapper);
      fullChartData[`${type}-${coinPair}`] = data.slice(candleStickLimit);
      chartData[`${type}-${coinPair}`] = data.slice(Number($sortByChangeInSticks.val()) * -1);
    }

    const lastCandles = data.slice(Number($sortByChangeInSticks.val()) * -1);
    const firstCandle = lastCandles[0];
    const lastCandle = lastCandles[lastCandles.length - 1];

    if (!firstCandle || !lastCandle) {
      return;
    }

    const firstClose = chartType === "candlestick" ? firstCandle.y[3] : firstCandle.y;
    const lastClose = chartType === "candlestick" ? lastCandle.y[3] : lastCandle.y;
    const change = ((lastClose - firstClose) / firstClose) * 100;

    // Update the order of the chart
    chartChange.push({ coinPair: coinPair, change: Math.abs(change) });
  };

  const orderCharts = () => {
    const items = $chartContainer.children("div").get();
    items.sort((a, b) => {
      const orderA = Number($(a).data("order") ?? 0);
      const orderB = Number($(b).data("order") ?? 0);

      return orderB - orderA;
    });

    const display = Number($("#display-n-charts").val());
    let counter = 1;

    $.each(items, (i, div) => {
      if (counter++ > display) {
        $(div).remove();

        const id = $(div).attr("id");
        
        console.log("Removing chart", $charts[id]);

      } else {
        $(div).show();
      }
      $(div)
        .find(".chart-template-subtitle")
        .text("#" + (i + 1).toString() + " " + $(div).find(".chart-template-subtitle").text());
      $chartContainer.append(div);
    });
  };

  const orderChartsRemoving = () => {
    chartChange.sort((a, b) => {
      return b.change - a.change;
    });

    const items = $chartContainer.children("div").get();

    $.each(items, (i, div) => {
      const id = $(div).attr("id");
        
      $charts[id].destroy();
      $charts[id] = null;
      $(div).remove();
    })

    const display = Number($("#display-n-charts").val());

    const finalChartChanges = chartChange.slice(0, display);

    let counter = 1;
    for (const chartChange of finalChartChanges) {
      const coinPair = chartChange.coinPair;

      const { isNew, $template: $newContainer } = appendNewContainer(coinPair);

      const currentText = $newContainer.find(".chart-template-subtitle").text();
      const position = "#" + (counter++).toString();
      const change = "(" + chartChange.change.toFixed(2) + "%" + ")";

      $newContainer.find(".chart-template-subtitle").text(`${position} ${currentText} ${change}`);

      loadChartData(chartEngine, chartType, coinPair);
    }
  };

  setInterval(() => updateChartsOrder(chartType), 5000);
});
